import { Injectable } from '@angular/core';
import { AuthHttpService } from "@appcore/services/index";
import { map, ReplaySubject } from "rxjs";
import {
    ICurrentActivity,
    IPermissionGroup,
    IShippingCompany,
    IShippingCompanySettings,
    IShippingCompanyUserNotificationSettings,
    IUserNotificationSettings
} from "@appcore/models/shipping-company";
import { SelectedCompany } from "@appcore/services/selected-company";

@Injectable({
    providedIn: 'root'
})
export class ShippingCompanyService {
    private baseUrlAdmin = '/api/admin/shipping-company';
    private baseUrl = '/api/shipping-company';

    constructor(private authHttp: AuthHttpService, private selected: SelectedCompany) {
    }

    get companies$() {
        return this.authHttp.get<IShippingCompany[]>(this.baseUrl).pipe(map(c => c.body));
    }

    company$(id: number) {
        return this.authHttp.get<IShippingCompany>(`${this.baseUrl}/${id}`).pipe(map(c => c.body));
    }

    createCompany(payload: { name: string }) {
        return this.authHttp.post<IShippingCompany>(this.baseUrlAdmin, payload).pipe(map(c => c.body));
    }

    updateCompany(id: number, payload: IShippingCompany) {
        return this.authHttp.put<IShippingCompany>(`${this.baseUrl}/${id}`, payload).pipe(map(c => c.body));
    }

    updateSettings(id: number, payload: IShippingCompanySettings) {
        return this.authHttp.put<IShippingCompanySettings>(`${this.baseUrlAdmin}/${id}/settings`, payload).pipe(map(c => c.body));
    }

    settings$(id: number) {
        return this.authHttp.get<IShippingCompanySettings>(`${this.baseUrlAdmin}/${id}/settings`).pipe(map(c => c.body));
    }

    deleteCompany(id: number) {
        return this.authHttp.delete(`${this.baseUrlAdmin}/${id}`);
    }

    get crewingPermissionGroups$() {
        return this.authHttp.get<IPermissionGroup[]>(`${this.baseUrl}/${this.selected.lastShippingCompany}/permission-groups/crewing`).pipe(map(c => c.body));
    }

    updateNotificationSettings(id: number, payload: IUserNotificationSettings) {
        return this.authHttp.post(
            `${this.baseUrl}/${id}/user-settings/notifications`, payload
        ).pipe(map(c => c.body));
    }

    notificationSettings$(id: number) {
        return this.authHttp.get<IUserNotificationSettings>(
            `${this.baseUrl}/${id}/user-settings/notifications`
        ).pipe(map(c => c.body));
    }

    allNotificationSettings$() {
        return this.authHttp.get<IShippingCompanyUserNotificationSettings[]>(
            `${this.baseUrl}/user-settings/notifications`
        ).pipe(map(c => c.body));
    }

	private _activities$ = new ReplaySubject<ICurrentActivity[] | null>(1);
	private _fetchedActivitiesAt = 0;
	private _cachingTimeInSeconds = 60;

	get activities$() {
		this.fetchActivities();

		return this._activities$.asObservable();
	}

	/**
	 * Caching activities.
	 */
	private fetchActivities() {
		if (this._fetchedActivitiesAt === 0 || Math.floor((Date.now() - this._fetchedActivitiesAt) / 1000) > this._cachingTimeInSeconds) {
			this._fetchedActivitiesAt = Date.now();
			this.authHttp.get<ICurrentActivity[]>(`${this.baseUrl}/${this.selected.lastShippingCompany}/activities`)
				.pipe(map(c => c.body))
				.subscribe({next: data => this._activities$.next(data), error: err => this._activities$.error(err)});
			return;
		}
	}
}
